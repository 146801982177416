<template>
  <div>
    <div v-if="isLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <!-- page start-->
    <section v-else>
      <div class="card">
        <header class="card-header">
          {{ $t("order.all") }}
          <span class="pull-right">
            <router-link
              :to="{ name: 'neworder' }"
              class="btn btn-success btn-sm"
              >{{ $t("links.order") }}
            </router-link>
          </span>
        </header>
        <Orders-Table
          :projects="getOrders.filter(x => x.statusNo >= 10)"
        ></Orders-Table>
      </div>
    </section>
    <!-- page end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OrdersTable from "@/components/OrdersTable.vue";

export default {
  name: "all",
  components: {
    OrdersTable
  },
  methods: {
    ...mapActions(["loadOrders"])
  },
  computed: mapGetters(["getOrders", "isLoading", "getUser"]),
  data() {
    return {
      fields: [
        {
          key: "orderNo",
          label: "Order no",
          sortable: true
        },
        {
          key: "projectNo",
          label: "Project no",
          sortable: true
        },
        {
          key: "projectName",
          label: "Project"
        },
        // {
        //     key: 'description'
        // },
        "status",
        {
          key: "numberoflines",
          label: "Number of lines",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
        {
          key: "created",
          sortable: true
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
        "edit"
      ],
      filter: null,
      filterOn: [],
      sortBy: "created",
      sortDesc: true
    };
  },
  created() {
    //check if the user is admin
    if (this.getUser.role === "Admin" || this.getUser.isSuperUser === true) {
      this.loadOrders();
    } else {
      this.$router.push("/");
    }
  }
};
</script>

<style></style>
